import { OrganizationAnalyticsOverall } from '../components/Organization/Details';
import { getFeatureQueryParamNumber } from '../hooks/useFeatureQueryParam';

export function OrganizationAnalyticsIndexRoute() {
  return (
    <OrganizationAnalyticsOverall
      exportEnabled
      showPrograms
      recentNSummaryDataPoints={getFeatureQueryParamNumber(
        'analytics-summary-data-points'
      )}
    />
  );
}

export const Component = OrganizationAnalyticsIndexRoute;

export function clientLoader() {
  return null;
}
